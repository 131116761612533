import { ComplexFieldLayoutPanelOwnProps } from '../../../panels/commons/complex-field-layout-panel/components/complex-field-layout-panel'
import CoreApi from '../core-api'
import { FormPlugin, FormsFieldPreset } from '@wix/forms-common'
import { COMPLEX_PHONE_ROLES } from '../../../constants/roles'
import { ComplexPhoneSettingsPanelProps } from '../../../panels/complex-phone-settings-panel/components/complex-phone-settings-panel'
import {
  getPhoneNumberField,
  getCountryCodeField,
  getCountryCodeOptions,
  createCustomListOptions,
  getCountryCodePlaceholderChoice,  
  getCountryCodeDataFromUserGEO,
} from './utils'

import { undoable } from '../decorators'
import _ from 'lodash'
import { fieldsStore } from '../preset/fields/fields-store'
import { CountriesCodesKeys } from '../preset/fields/complex-fields/complex-phone/constants'
import { FedopsLogger } from '@wix/fedops-logger'
import { removeFieldFromList } from '../fields/utils'
import { ComplexFieldExtraData, FieldExtraData } from '../preset/fields/field-types-data'
import { areFieldOptionsEqual } from '../../../utils/utils'

export enum CountryCodePlaceholderChoice {
  None = 'none',
  PlaceholderText = 'placeholderText',
  CodeList = 'codeList',
}

export enum CountryCodeListChoice {
  AllCountries = 'allCountries',
  CustomList = 'customList',
}

export const COMPLEX_PHONE_FIELD = {
  MARGIN: 8,
  COUNTRY_CODE: {
    MIN_WIDTH: 114,
  },
  PHONE_NUMBER: {
    DEFAULT_WIDTH: 242,
  },
}

export default class ComplexPhoneApi {
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private fedopsLogger: FedopsLogger
  private biLogger: any
  private experiments: any

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger, experiments, fedopsLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
    this.fedopsLogger = fedopsLogger
  }

  private async _getCountryCodeFieldLayout(
    referenceField: FormField,
  ): Promise<{ width: number; x: number; y: number }> {
    const minWidth = COMPLEX_PHONE_FIELD.COUNTRY_CODE.MIN_WIDTH
    const marginRight = COMPLEX_PHONE_FIELD.MARGIN
    let x = 0,
      y = 0,
      width = minWidth

    if (referenceField) {
      const { x: refX, y: refY } = await this.boundEditorSDK.components.layout.get({
        componentRef: referenceField.componentRef,
      })
      width = Math.max(refX - marginRight, minWidth)
      y = refY
    }

    return {
      width,
      x,
      y,
    }
  }

  private async _getPhoneNumberFieldLayout(
    referenceField: FormField,
    complexPhoneWidgetWidth: number,
  ): Promise<{ width: number; x: number; y: number }> {
    const marginRight = COMPLEX_PHONE_FIELD.MARGIN
    let width = COMPLEX_PHONE_FIELD.PHONE_NUMBER.DEFAULT_WIDTH
    let x = complexPhoneWidgetWidth - width
    let y = 0

    if (referenceField) {
      const { x: refX, y: refY, width: refWidth } = await this.boundEditorSDK.components.layout.get(
        {
          componentRef: referenceField.componentRef,
        },
      )

      x = refX + refWidth + marginRight
      width = complexPhoneWidgetWidth - x
      y = refY
    }

    return {
      width,
      x,
      y,
    }
  }

  public getCountryCodeState(widgetField: FormField) {
    const { childFields } = widgetField
    const countryCodeOptions = getCountryCodeOptions(childFields)
    const countryCodeDefaultOptions =
      fieldsStore.allFieldsData[FormsFieldPreset.COMPLEX_PHONE_DROPDOWN].properties.extraData.data
        .options

    const countryCodeListChoice = areFieldOptionsEqual(countryCodeDefaultOptions, countryCodeOptions)
      ? CountryCodeListChoice.AllCountries
      : CountryCodeListChoice.CustomList

    const countryCodeField: FormField = getCountryCodeField(childFields)
    return {
      countryCodePlaceholderChoice: getCountryCodePlaceholderChoice(countryCodeField),
      countryCodePlaceholderValue: _.get(countryCodeField, 'placeholder.text', ''),
      countryCodeDefaultOption: _.get(countryCodeField, 'defaultValue', ''),
      countryCodeListChoice,
      countryCodeOptions,
      countryCodeUserOptions:
        countryCodeListChoice === CountryCodeListChoice.CustomList
          ? countryCodeOptions
          : createCustomListOptions(),
    }
  }

  public async loadInitialSettingsPanelData({
    componentRef,
  }: {
    componentRef: ComponentRef
  }): Promise<Partial<ComplexPhoneSettingsPanelProps>> {
    const complexPhoneWidget = await this.coreApi.fields.getField(componentRef)
    const childFields = complexPhoneWidget.childFields
    let phoneFormat = ''
    let pattern = ''

    const phoneNumberField = getPhoneNumberField(childFields)
    if (phoneNumberField) {
      const {
        phoneFormat: _phoneFormat,
        pattern: _pattern,
      } = (await this.boundEditorSDK.components.data.get({
        componentRef: phoneNumberField.componentRef,
      })) as any
      phoneFormat = _phoneFormat || ''
      pattern = _pattern || ''
    }
    const shouldShowFieldTitle = !!(childFields.length > 0 && childFields[0].showLabel)

    return {
      complexPhoneWidget,
      phoneFormat,
      pattern,
      shouldShowFieldTitle,
      ...this.getCountryCodeState(complexPhoneWidget),
    }
  }

  public async loadInitialLayoutPanelData({
    componentRef,
  }): Promise<Partial<ComplexFieldLayoutPanelOwnProps>> {
    const complexPhone = await this.coreApi.fields.getField(componentRef)
    const subFields = complexPhone.childFields

    const phoneNumberField = getPhoneNumberField(subFields)

    return {
      fields: subFields,
      textPadding: phoneNumberField.textPadding,
      textAlignment: phoneNumberField.textAlignment,
      labelMargin: phoneNumberField.labelMargin,
      labelPadding: phoneNumberField.labelPadding,
      hasLabel: phoneNumberField.showLabel,
    }
  }

  public updateRequired(complexPhoneWidget: FormField, required: boolean) {
    return Promise.all(
      complexPhoneWidget.childFields.map((childField: FormField) => {
        return this.boundEditorSDK.components.properties.update({
          componentRef: childField.componentRef,
          props: { required },
        })
      }),
    )
  }

  public async handleComponentAddedToComplexPhone(
    controllerRef: ComponentRef,
    compRef: ComponentRef,
  ): Promise<void> {
    const { role } = await this.coreApi.getComponentConnection(compRef)

    if (_.values(COMPLEX_PHONE_ROLES).includes(role)) {
      const existingFields = await this.coreApi.findConnectedComponentsByRole(controllerRef, role)
      if (existingFields.length > 1) {
        return this.coreApi.removeComponentRef(compRef)
      }
    }
  }

  @undoable()
  public async addPhoneNumberField(
    complexPhoneWidget: FormField,
    formComponentRef: ComponentRef,
    formComponentConnection: ComponentConnection,
    plugins: FormPlugin[],
  ): Promise<FormField> {
    const role = COMPLEX_PHONE_ROLES.TEXT
    this.fedopsLogger.interactionStarted(`add-${role}-api`)

    const { childFields, componentRef: widgetRef } = complexPhoneWidget

    const existingPhoneChildField = await this.coreApi.findConnectedComponent(
      widgetRef,
      COMPLEX_PHONE_ROLES.TEXT,
    )
    if (existingPhoneChildField) {
      return this._getPhoneFieldAfterFieldAddition(existingPhoneChildField, complexPhoneWidget)
    }
    const countryCodeField = getCountryCodeField(childFields)
    const extraData: FieldExtraData = {
      props: {
        required: false,
      },
      role,
    }
    const { width: complexPhoneWidgetWidth } = await this.boundEditorSDK.components.layout.get({
      componentRef: complexPhoneWidget.componentRef,
    })
    const layout = await this._getPhoneNumberFieldLayout(countryCodeField, complexPhoneWidgetWidth)

    return this.coreApi.fields.addFieldInComplexWidget({
      complexWidgetRef: complexPhoneWidget.componentRef,
      formComponentRef,
      formComponentConnection,
      plugins,
      layout,
      fieldType: FormsFieldPreset.COMPLEX_PHONE_TEXT,
      extraData,
    })
  }

  @undoable()
  public async addCountryCodeField(
    complexPhoneWidget: FormField,
    formComponentRef: ComponentRef,
    formComponentConnection: ComponentConnection,
    plugins: FormPlugin[],
    previousCountryCodeData: ComponentData,
  ): Promise<FormField> {
    const role = COMPLEX_PHONE_ROLES.DROPDOWN
    this.fedopsLogger.interactionStarted(`add-${role}-api`)

    const { childFields } = complexPhoneWidget
    const userGEO = (await this.coreApi.getUserGEO()) as CountriesCodesKeys
    const data = previousCountryCodeData || getCountryCodeDataFromUserGEO(userGEO)
    const phoneNumberField = getPhoneNumberField(childFields)
    const extraData: FieldExtraData = {
      role,
      props: { required: !!(phoneNumberField && phoneNumberField.required) },
      data,
    }
    const layout = await this._getCountryCodeFieldLayout(phoneNumberField)
    const fieldType = FormsFieldPreset.COMPLEX_PHONE_DROPDOWN
    return this.coreApi.fields.addFieldInComplexWidget({
      complexWidgetRef: complexPhoneWidget.componentRef,
      formComponentRef,
      formComponentConnection,
      plugins,
      layout,
      fieldType,
      extraData,
    })
  }

  private async _getPhoneFieldAfterFieldAddition(
    ref: ComponentRef,
    complexPhoneWidget: FormField,
  ): Promise<FormField> {
    const field = await this.coreApi.fields.getField(ref, true)

    return { ...complexPhoneWidget, childFields: [...complexPhoneWidget.childFields, field] }
  }

  @undoable()
  public async removeCountryCode(
    complexPhoneWidget: FormField,
  ): Promise<{ complexPhoneWidget: FormField; previousFieldData }> {
    this.fedopsLogger.interactionStarted('remove-country-code-field-api')

    const countryCodeField = getCountryCodeField(complexPhoneWidget.childFields)
    let previousFieldData
    if (countryCodeField) {
      previousFieldData = await this.boundEditorSDK.components.data.get({
        componentRef: countryCodeField.componentRef,
      })
      await this.coreApi.removeComponentRef(countryCodeField.componentRef)
      const childFields = removeFieldFromList(complexPhoneWidget.childFields, countryCodeField)

      return { complexPhoneWidget: { ...complexPhoneWidget, childFields }, previousFieldData }
    }

    this.fedopsLogger.interactionEnded('remove-country-code-field-api')

    return { complexPhoneWidget, previousFieldData: null }
  }

  private async _getDefaultCountryCodeOptions(
    countryCodePlaceholderChoice: CountryCodePlaceholderChoice,
  ) {
    const defaultData =
      fieldsStore.allFieldsData[FormsFieldPreset.COMPLEX_PHONE_DROPDOWN].properties.extraData.data
    let newOptions = _.cloneDeep(defaultData)
    const shouldUpdateDefaultValue =
      countryCodePlaceholderChoice === CountryCodePlaceholderChoice.CodeList
    if (!shouldUpdateDefaultValue) {
      delete newOptions.value
      delete newOptions.placeholder
    } else {
      const userGEO = (await this.coreApi.getUserGEO()) as CountriesCodesKeys
      const fieldData = getCountryCodeDataFromUserGEO(userGEO)

      newOptions = {
        ...newOptions,
        ...fieldData.data,
      }
    }

    return newOptions
  }

  public async restoreDefaultCountryCodeOptions(
    countryCodeRef: ComponentRef,
    countryCodePlaceholderChoice: CountryCodePlaceholderChoice,
  ): Promise<{ options: FieldOption[]; defaultOption: string }> {
    const defaultCodeOptions = await this._getDefaultCountryCodeOptions(
      countryCodePlaceholderChoice,
    )

    await this.boundEditorSDK.components.data.update({
      componentRef: countryCodeRef,
      data: defaultCodeOptions,
    })

    return { options: defaultCodeOptions.options, defaultOption: defaultCodeOptions.value }
  }

  public async restoreUserDefinedOptions(
    countryCodeRef: ComponentRef,
    userDefinedOptions: FieldOption[],
    countryCodePlaceholderChoice: CountryCodePlaceholderChoice,
  ): Promise<{ options: FieldOption[]; defaultOption: string }> {
    const firstOptionText = userDefinedOptions[0].value
    const data = {
      options: userDefinedOptions,
      ...(countryCodePlaceholderChoice === CountryCodePlaceholderChoice.CodeList
        ? {
            value: firstOptionText,
          }
        : {}),
    }
    await this.boundEditorSDK.components.data.update({
      componentRef: countryCodeRef,
      data: data,
    })
    return { options: userDefinedOptions, defaultOption: data.value }
  }

  public changePhoneFormat(
    phoneRef: ComponentRef,
    phoneFormat: string | null,
    pattern: string | null,
  ) {
    return this.boundEditorSDK.components.data.update({
      componentRef: phoneRef,
      data: { phoneFormat, pattern },
    })
  }
}
