import CoreApi from '../core-api'
import { ComplexFieldLayoutPanelOwnProps } from '../../../panels/commons/complex-field-layout-panel/components/complex-field-layout-panel'
import _ from 'lodash'
import { fieldsStore } from '../preset/fields/fields-store'
import { FormPlugin, FormsFieldPreset } from '@wix/forms-common'
import { ComplexAddressSettingsPanelProps } from '../../../panels/complex-address-settings-panel/components/complex-address-settings-panel'
import { undoable } from '../decorators'
import { FieldExtraData } from '../preset/fields/field-types-data'
import { createCustomListOptions, getCountryOptions } from './utils'
import { areFieldOptionsEqual } from '../../../utils/utils'

export enum CountryListType {
  AllCountries = 'AllCountries',
  CustomList = 'CustomList',
}
export default class ComplexAddressApi {
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private biLogger: any
  private experiments: any

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
  }

  public async loadInitialLayoutPanelData({
    componentRef,
  }): Promise<Partial<ComplexFieldLayoutPanelOwnProps>> {
    const complexAddress = await this.coreApi.fields.getField(componentRef)
    const subFields = complexAddress.childFields

    const lastField = _.last(subFields)

    return {
      fields: subFields,
      textAlignment: lastField.textAlignment,
      textPadding: lastField.textPadding,
      labelMargin: lastField.labelMargin,
      labelPadding: lastField.labelPadding,
      hasLabel: lastField.showLabel,
    }
  }

  public async loadInitialSettingsPanelData({
    componentRef,
  }: {
    componentRef: ComponentRef
  }): Promise<Partial<ComplexAddressSettingsPanelProps>> {
    const complexAddressWidget = await this.coreApi.fields.getField(componentRef)
    const countryOptions = getCountryOptions(complexAddressWidget.childFields)
    const defaultCountryList =
      fieldsStore.allFieldsData[FormsFieldPreset.COMPLEX_ADDRESS_COUNTRY].properties.extraData.data
        .options
    let countryListType
    if (countryOptions.length === 0) {
      countryListType = CountryListType.AllCountries
    } else {
      countryListType = areFieldOptionsEqual(defaultCountryList, countryOptions)
        ? CountryListType.AllCountries
        : CountryListType.CustomList
    }
    const countryUserOptions =
      countryListType === CountryListType.CustomList ? countryOptions : createCustomListOptions()

    return {
      complexAddressWidget,
      countryListType,
      countryUserOptions,
    }
  }

  @undoable()
  public async addFieldByRole(
    complexAddressRef: ComponentRef,
    formComponentRef: ComponentRef,
    role: string,
    formComponentConnection: ComponentConnection,
    plugins: FormPlugin[],
    previousProperties: { layout?: FieldLayout; data?: any } = {},
  ): Promise<FormField> {
    const fieldDefinition = fieldsStore.complexAddressField.find((f) => f.role === role)
    const extraData: FieldExtraData = {
      props: {
        required: false,
      },
      role,
      data: previousProperties.data,
    }

    return this.coreApi.fields.addFieldInComplexWidget({
      complexWidgetRef: complexAddressRef,
      formComponentRef,
      formComponentConnection,
      plugins,
      fieldType: fieldDefinition.fieldType,
      extraData,
      layout: previousProperties.layout,
    })
  }

  @undoable()
  public async removeFieldByRef(componentRef: ComponentRef): Promise<FieldLayout> {
    const previousProperties = await this.boundEditorSDK.components.get({
      componentRefs: [componentRef],
      properties: ['layout', 'data'],
    })

    this.coreApi.removeComponentRef(componentRef)

    return _.first(previousProperties)
  }

  public updateRequiredProperty(
    requiredFieldRefs: ComponentRef[],
    notRequiredFieldRefs: ComponentRef[],
  ) {
    const set = (componentRef: ComponentRef, required: boolean) =>
      this.boundEditorSDK.components.properties.update({ componentRef, props: { required } })

    return Promise.all([
      ...requiredFieldRefs.map((componentRef) => set(componentRef, true)),
      ...notRequiredFieldRefs.map((componentRef) => set(componentRef, false)),
    ])
  }

  public async restoreDefaultCountryList(
    countryRef: ComponentRef,
  ): Promise<{ options: FieldOption[]; defaultOption: string }> {
    const defaultData = {
      ...fieldsStore.allFieldsData[FormsFieldPreset.COMPLEX_ADDRESS_COUNTRY].properties.extraData
        .data,
    }

    await this.boundEditorSDK.components.data.update({
      componentRef: countryRef,
      data: defaultData,
    })

    return { options: defaultData.options, defaultOption: defaultData.value }
  }

  public async restoreUserDefinedOptions(
    countryRef: ComponentRef,
    userDefinedOptions: FieldOption[],
  ): Promise<{ options: FieldOption[]; defaultOption: string }> {
    const data = {
      options: userDefinedOptions,
    }
    await this.boundEditorSDK.components.data.update({
      componentRef: countryRef,
      data: data,
    })
    return {
      options: userDefinedOptions,
      defaultOption: _.get(userDefinedOptions, '[0].value', ''),
    }
  }
}
